<template>
  <div class="project-list">
    <div>
        <a-tabs v-model:activeKey="currentTab" @change="pageChange(1)">
          <a-tab-pane key="2" tab="我的发布"></a-tab-pane>
          <a-tab-pane key="1" tab="我的接收"></a-tab-pane>
        </a-tabs>
      </div>
    <div class="project-list-header">
      <CategorySearch :source="filterSource" @change="search" />
      <a-button v-show="currentTab == 2" type="primary" @click="handleAdd">添加</a-button>
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotNoticeUser="{ row }">
        <a-tooltip placement="topLeft">
          <template #title>{{row.noticeUserInfo.map((item) => item.userName).join(",")}}</template>
          <span>{{
            row.noticeUserInfo.map((item) => item.userName).join(",")
          }}</span>
        </a-tooltip>
      </template>
      <template #slotCount="{ row }">
        <a>{{ row.readCount }}</a>
      </template>
    </BasicTable>
  </div>
  <editModal
    v-if="addModalVisible"
    v-model:visible="addModalVisible"
    :info="currentRow"
    @success="editSuccess"
  ></editModal>
  <logModal
    v-if="detailModalVisible"
    :info="currentRow"
    v-model:visible="detailModalVisible"
  ></logModal>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiMessage } from "@/api/backManage/message.js";
import { apiRole } from "@/api/authority.js";
import editModal from "./components/editModal";
import logModal from "./components/logModal";
import _ from "lodash";
import { Modal, message } from "ant-design-vue";
import { apiDictListByCode } from "@/api/common";
import { useUserStore } from "@/store/modules/user";

const userStore = useUserStore();
onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initFilterOption();
  initTableData();
  initOptions();
});

const currentTab = ref('2');
const messageTypeMap = ref({});
const messageStatus = ref({});
const initOptions = async () => {
  apiDictListByCode("MESSAGE_TYPE").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageTypeMap.value = _map;
  });
  apiDictListByCode("MESSAGE_STATUS").then((res) => {
    const _map = {};
    res.result.forEach((item) => {
      _map[item.value] = item.label;
    });
    messageStatus.value = _map;
  });
};

const filterSource = ref([
  {
    label: "租户",
    type: "select",
    bindKey: "tenantId",
    options: [],
    fieldNames: {
      label: "tenantName",
      value: "tenantId",
    },
  },
  {
    label: "标题名称",
    type: "input",
    bindKey: "msgTitle",
    placeholder: "请输入",
  },
]);

const initFilterOption = async () => {
  let { result } = await apiRole.tenants();
  const _list = _.cloneDeep(filterSource.value);
  _list[0].options = result || [];
  filterSource.value = _list;
};
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
  },
  columns: [
    {
      title: "ID",
      type: "text",
      key: "bizMsgId",
      width: 120,
    },
    {
      title: "租户名称",
      type: "text",
      key: "tenantName",
      ellipsis: true,
    },
    {
      title: "标题",
      type: "text",
      key: "msgTitle",
    },
    {
      title: "类型",
      type: "text",
      key: "noticeType",
      width: 100,
      formatter(val) {
        return messageTypeMap.value[val];
      },
    },
    {
      title: "发布用户",
      type: "text",
      key: "userName",
      width: 140,
      ellipsis: true,
    },
    {
      title: "接收用户",
      type: "customize",
      key: "noticeUserInfo",
      width: 200,
      slotName: "slotNoticeUser",
      ellipsis: true,
    },
    {
      title: "发布状态",
      type: "text",
      key: "msgStatus",
      width: 100,
      formatter(val) {
        return messageStatus.value[val];
      },
    },
    {
      title: "发布时间",
      type: "text",
      key: "publishTimeStr",
    },
    {
      title: "已阅读",
      type: "customize",
      key: "readCount",
      slotName: "slotCount",
      width: 80,
    },
  ],
  action: {
    title: "操作",
    width: 120,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "EyeOutlined",
        iconTip: "查看",
        visibleFn(row) {
          return row.msgStatus == "02";
        },
        onClick: (row) => {
          handleCheck(row);
        },
      },
      {
        icon: "FormOutlined",
        iconTip: "编辑",
        type: "icon",
        visibleFn(row) {
          return row.msgStatus == "01" && userStore.userInfo.userId == row.userId;
        },
        onClick: (row) => {
          handleEdit(row);
        },
      },
      {
        icon: "DeleteOutlined",
        iconTip: "删除",
        type: "icon",
        onClick: (row) => {
          handleDel(row);
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
    type: currentTab.value*1,
  };
  tableConfig.loading = true;
  try {
    tableRef.value.dataSourceRef = [];
    const res = await apiMessage.pageUser(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const addModalVisible = ref(false);
const currentRow = ref(null);
const handleAdd = () => {
  addModalVisible.value = true;
  currentRow.value = null;
};

const detailModalVisible = ref(false);
const handleCheck = (row) => {
  currentRow.value = row;
  detailModalVisible.value = true;
};

const handleDel = async ({ id }) => {
  Modal.confirm({
    title: "提醒",
    content: "请确认是否删除？",
    async onOk() {
      await apiMessage.remove(id);
      message.success("删除成功");
      initTableData();
    },
  });
};

const handleEdit = (row) => {
  addModalVisible.value = true;
  console.log(234, row);
  currentRow.value = row;
};

const editSuccess = () => {
  addModalVisible.value = false;
  initTableData();
};
</script>
<style lang="less" scoped>
.project-list {
  height: 100%;
  padding:  0 20px 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
</style>
