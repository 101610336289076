import http from "../utils/http";

// 应用菜单接口
export const apiMenu = {
  moduleList() {
    return http.request({
      url: "/oauth/menu/modules",
      method: "get",
    });
  },
  menuList(moduleId) {
    return http.request({
      url: "/oauth/menu/module/menus",
      method: "get",
      params: {
        moduleId,
      },
    });
  },
  menuListByTenant(tenantId) {
    return http.request({
      url: `/oauth/menu/menus/tenant/${tenantId}`,
      method: "get",
    });
  },
  edit(data) {
    return http.request({
      url: "/oauth/menu/change",
      method: "put",
      data,
    });
  },
};

// 角色管理接口
export const apiRole = {
  page(params) {
    return http.request({
      url: "/oauth/role/page/list",
      method: "get",
      params,
    });
  },
  delete(roleId) {
    return http.request({
      url: `/oauth/role/${roleId}`,
      method: "delete",
    });
  },
  add(data) {
    return http.request({
      url: "/oauth/role",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/oauth/role",
      method: "put",
      data,
    });
  },
  tenants() {
    return http.request({
      url: "/oauth/tenant/tenants/selective",
      method: "get",
    });
  },
  tenants_v1() {
    return http.request({
      url: "/oauth/tenant/tenants/v1/selective",
      method: "get",
    });
  },
  listByTenant(tenantId) {
    return http.request({
      url: "/oauth/role/tenant/list",
      method: "get",
      params: {
        tenantId,
      },
    });
  },
};

// 用户管理接口
export const apiUser = {
  page(params) {
    return http.request({
      url: "/oauth/user/users",
      method: "get",
      params,
    });
  },
  delete(userId) {
    return http.request({
      url: `/oauth/user/${userId}`,
      method: "delete",
    });
  },
  resetPassword(userId) {
    return http.request({
      url: `/oauth/user/password/reset/${userId}`,
      method: "put",
    });
  },
  detail(userId) {
    return http.request({
      url: `/oauth/user/info/${userId}`,
      method: "get",
    });
  },
  projectTree(params) {
    return http.request({
      url: `/cemp-bms/management-node/tenant/list`,
      method: "get",
      params,
    });
  },
  add(data) {
    return http.request({
      url: "/oauth/user/creation",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/oauth/user/change",
      method: "put",
      data,
    });
  },
  validation(params) {
    return http.request({
      url: "/oauth/user/param/validation",
      method: "get",
      params,
    });
  },
  actionLog(data) {
    return http.request({
      url: "/cemp-bms/log/opr/list",
      method: "post",
      data,
    });
  },
  controlDetail(userId) {
    return http.request({
      url: `/cemp-oauth/user-control/info/${userId}`,
      method: "get"
    });
  },
  controlSet(data) {
    return http.request({
      url: `/cemp-oauth/user-control/change`,
      method: "put",
      data
    });
  },
  controlCurrent(data) {
    return http.request({
      url: `/cemp-oauth/user-control/check/current`,
      method: "get",
      data
    });
  },
};
